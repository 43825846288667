import React from "react";
import "@rainbow-me/rainbowkit/styles.css";
// import "highlight.js/styles/vs2015.css";
import "../textEditor.css";
import "../globals.css";
import Script from "next/script";

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps }) {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <>
      {isProduction && (
        <Script
          src="https://plausible.io/js/script.js"
          defer
          data-domain="docs.pluto.xyz"
        />
      )}
      <Component {...pageProps} />
    </>
  );
}
